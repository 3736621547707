import Cookies from "universal-cookie";

import { api } from "./base";

const cookies = new Cookies();

const merchantToken = cookies.get("merchantToken");
const shopId = cookies.get("shopId");

// onboarding apis ---
export const send_sample_review = ({ phoneNumber, merchantToken, shopId }) =>
  api.post(`/onboard/send-sample-review`, {
    shopId: shopId,
    merchantToken: merchantToken,
    phoneNumber: "+" + phoneNumber,
  });

export const get_review_reply = ({ phoneNumber, reviewRequestId }) =>
  api.get(`/onboard/get-review-replay`, {
    shopId: shopId,
    merchantToken: merchantToken,
    phoneNumber: phoneNumber,
    reviewRequestId: reviewRequestId,
  });

export const import_past_orders = (
  orderBackupDays,
  merchantToken = merchantToken,
  shopId = shopId
) =>
  api.patch(`/importorders`, {
    merchantToken: merchantToken,
    shopId: shopId,
    orderBackupDays: orderBackupDays,
  });

export const manage_tour = (
  status,
  merchantToken = merchantToken,
  shopId = shopId
) =>
  api.patch(`/onboard/onboard-complete`, {
    merchantToken: merchantToken,
    shopId: shopId,
    onbroardStatus: status,
  });

export const manage_review_status = (
  status,
  merchantToken = merchantToken,
  shopId = shopId
) =>
  api.patch(`/dashboard/update-review-request-status`, {
    merchantToken: merchantToken,
    shopId: shopId,
    reviewStatus: status,
  });

export const dashboard_data = ({
  days,
  token = merchantToken,
  SHOPID = shopId,
}) =>
  api.get(`/dashboard/dashboard-widget-data`, {
    params: {
      merchantToken: token,
      shopId: SHOPID,
      dateFilter: days,
    },
  });

export const get_active_plan = ({ token = merchantToken, SHOPID = shopId }) =>
  api.get(`/subscription/shop-subscription-package`, {
    params: {
      merchantToken: token,
      shopId: SHOPID,
    },
  });

export const reschedule_expired_reviews = (
  merchantToken = merchantToken,
  shopId = shopId
) =>
  api.post(`/subscription/reschedule-expiry-reviews`, {
    // params: {
    merchantToken: merchantToken,
    shopId: shopId,
    // },
  });

export const reschedule_past_reviews = (
  merchantToken = merchantToken,
  shopId = shopId
) =>
  api.post(`/reschedule-past-reviews`, {
    // params: {
    merchantToken: merchantToken,
    shopId: shopId,
    // },
  });

//  pricing apis
export const get_pricing_plans = ({ token, SHOPID }) =>
  api.get(`/subscription/plans`, {
    params: {
      merchantToken: token,
      shopId: SHOPID,
    },
  });

export const upgrade_store_plan = (
  id,
  planType = "month",
  shopId,
  merchantToken
) =>
  api.post(`/subscription/update-store-plan`, {
    merchantToken: merchantToken,
    shopId: shopId,
    planId: id,
    planType: planType,
  });

// billing
export const get_billing_charges = ({ token, SHOPID, dateFilter = 0 }) =>
  api.get(`/billing/get-charges`, {
    params: {
      merchantToken: token,
      shopId: SHOPID,
      dateFilter: dateFilter,
    },
  });

// activate
export const get_shop_data = ({ uid }) =>
  api.get(`/shopauth`, {
    params: {
      uid: uid,
    },
  });
// activate
export const get_site_widget_config = ({ shopId }) =>
  api.get(`/site-widget-config`, {
    params: {
      storeId: shopId,
    },
  });

export const update_full_page_widget_config = ({ shopId, body }) =>
  api.post(`/update-config-widget`, {
    shopId: shopId,
    fullPageWidget: body,
  });

export const update_floating_widget_config = ({ shopId, body }) =>
  api.post(`/update-config-widget`, {
    shopId: shopId,
    floatingWidget: body,
  });

export const update_home_widget_config = ({ shopId, body }) =>
  api.post(`/update-config-widget`, {
    shopId: shopId,
    homePageWidget: body,
  });
export const update_theme_config = ({ shopId, body }) =>
  api.post(`/update-theme-widget`, {
    shopId: shopId,
    shopifyThemeWidget: body,
  });
export const get_theme_config = ({ shopId }) =>
  api.get(`/site-widget-theme`, {
    params: {
      storeId: shopId,
    },
  });

export const update_billing_limit = (shopId, merchantToken, status) =>
  api.post(`/billing/update-send-review-planlimit`, {
    shopId: shopId,
    merchantToken: merchantToken,
    status: status,
  });

export const send360Token = (shopId, merchantToken, apikey, credentials) =>
  api.patch(`/wa/bsp/manage-token`, {
    shopId: shopId,
    merchantToken: merchantToken,
    apikey: apikey,
    credentials: credentials,
  });

export const getApiKey = (
  shopId,
  merchantToken,
  partnerId,
  channelId,
  accessToken
) =>
  api.post(`/wa/bsp/get-api-key`, {
    shopId: shopId,
    merchantToken: merchantToken,
    partnerId: partnerId,
    channelId: channelId,
    accessToken: accessToken,
  });

export const getChannelData = (shopId, merchantToken) =>
  api.get(`/wa/bsp/get-provider-settings`, {
    params: {
      shopId: shopId,
      merchantToken: merchantToken,
    },
  });

export const manage_api_key = (
  shopId,
  merchantToken,
  apikey,
  channelId,
  appId,
  phoneNumber,
  whatsappAccountId
) =>
  api.patch(`/wa/bsp/manage-api-key`, {
    shopId: shopId,
    merchantToken: merchantToken,
    apikey: apikey,
    channelId: channelId,
    appId: appId,
    phoneNumber: phoneNumber,
    whatsappAccountId: whatsappAccountId,
  });

export const demoLogin = (name, email) =>
  api.post(`/demo/user-login`, {
    name: name,
    email: email,
  });

  export const createDemoRequest = (phone) =>
  api.post(`/demo/create-review-request`, {
    phoneNumber: phone,
  });

const main_apis = {
  createDemoRequest,
  manage_api_key,
  demoLogin,
  getChannelData,
  getApiKey,
  send360Token,
  update_billing_limit,
  update_theme_config,
  get_theme_config,
  update_full_page_widget_config,
  update_floating_widget_config,
  update_home_widget_config,
  get_site_widget_config,
  send_sample_review,
  get_review_reply,
  import_past_orders,
  manage_tour,
  manage_review_status,
  dashboard_data,
  get_active_plan,
  get_pricing_plans,
  upgrade_store_plan,
  reschedule_expired_reviews,
  reschedule_past_reviews,
  get_billing_charges,
  get_shop_data,
};

export default main_apis;
